<template>
    <div>
        <div class="modal-row2 modal-height-max">
            <div class="modal-body modal-row2 card" style="min-height: 60vh;">
                <div class="card pl-3 pt-2 mt-2 pr-3 pb-1">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 mb-2">
                            <label class="ml-1">{{ t('NAV.ALLOTMENT') }}</label>
                            <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Loteamento' }" id="alot"
                                     name="alot" v-model="allotment_id"
                                     :options="allotments" :disabled="allotments?.length <= 1"/>
                            <div class="validation" v-if="isAllotmentInvalid">{{
                                    t('GENERAL.MSG.REQUIRED_FIELD')
                                }}
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <label class="ml-1 required">{{ t('LOTS.APPLY_IN') }}:</label>
                            <Select2 :settings="{ width: '100%', placeholder: 'Selecione' }" id="apllyIn"
                                     name="apllyIn" v-model="applyType"
                                     :options="optionsApply" @select="changeAllotment()" :disabled="!allotment_id"/>
                            <div class="validation" v-if="isApplyInvalid">{{ t('GENERAL.MSG.REQUIRED_FIELD') }}</div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="valueReadjsutment" class="required ml-1">{{ t('LOTS.VALUE') }}: </label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-secondary dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {{
                                                valueReadjustment.type == 0 ? t('LOTS.FIXED') :
                                                        valueReadjustment.type == 2 ? t('LOTS.FOOTAGE') :
                                                                valueReadjustment.type == 1 ? t('LOTS.PERCENTAGE') : t('GENERAL.TYPE')
                                            }}
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item bg-hover-white"
                                               @click="valueReadjustment.type = null; valueReadjustment.value = null ">{{
                                                    t('GENERAL.CLEAR')
                                                }}</a>
                                            <a class="dropdown-item bg-hover-white" @click="createConfigMoney('FIXED')">{{
                                                    t('LOTS.FIXED')
                                                }}</a>
                                            <a class="dropdown-item bg-hover-white"
                                               @click="createConfigMoney('PERCENTAGE')">{{
                                                    t('LOTS.PERCENTAGE')
                                                }}</a>
                                            <a class="dropdown-item bg-hover-white"
                                               @click="createConfigMoney('FOOTAGE')">{{
                                                    t('LOTS.FOOTAGE')
                                                }}</a>
                                        </div>
                                    </div>
                                    <input class="form-control" type="text" id="valueReadjsutment"
                                           :disabled="valueReadjustment.type  === null" v-money3="valueConfig"
                                           name="valueReadjsutment" v-model.lazy="valueReadjustment.value">
                                </div>
                                <div class="validation" v-if="isValueInvalid">{{
                                        t('GENERAL.MSG.REQUIRED_FIELD')
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="applyType === '0'" class="accordion toggle-icons" id="toggleIconsAllotment">
                    <div class="accordion-container">
                        <div class="accordion-header" id="toggleIconsAllotments">
                            <a data-toggle="collapse" :data-target="'#toggleIconsCollapseM' + allotment.text"
                               :id="`openBlock${allotment.text}`"
                               :aria-controls="'toggleIconsCollapse' + allotment.text"
                               @click="allotment.open = !allotment.open" style="cursor: default">
                                {{ allotment.text }}
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="applyType === '1'">
                    <div v-for="(block, key) in blocks" :key="key">
                        <div class="accordion toggle-icons" :id="`toggleIconsBlock${block.text}`">
                            <div class="accordion-container">
                                <div class="accordion-header" :id="`toggleIconsBlocks${block.text}`">
                                    <div class="row">
                                        <a data-toggle="collapse"
                                           :data-target="`#toggleIconsCollapseAllotment${block.text}`"
                                           :id="`openBlock${block.text}`"
                                           class="col-6"
                                           aria-controls="toggleIconsCollapseAllotment">
                                            {{ t('PROPERTIES.BLOCK') }} {{ block.text }}
                                        </a>
                                        <div class="col-3"></div>
                                        <div class="custom-control custom-switch list-primary text-right col-3">
                                            <input type="checkbox" class="custom-control-input" :value="block.id"
                                                   v-model="blockSelect"
                                                   :id="`customSwitch${key}`">
                                            <label class="custom-control-label mr-2"
                                                   :for="`customSwitch${key}`"
                                                   :style="isInvalidSelected ? 'color: red': ''">Selecionar</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="applyType === '2'">
                    <div v-for="(block, key) in blocks" :key="block">
                        <div class="accordion toggle-icons" id="toggleIcons">
                            <div class="accordion-container">
                                <div class="accordion-header" :id="'toggleIcons' + block.title">
                                    <a data-toggle="collapse" :data-target="'#toggleIconsCollapseM' + block.title"
                                       :id="`openBlock${block.text}`"
                                       :aria-expanded="blocks[key].open ? true :  false"
                                       :aria-controls="'toggleIconsCollapseM' + block.title"
                                       @click="indexLotsByAllotment(allotment_id, block.text); blocks[key].open = !blocks[key].open"
                                       :style="isInvalidSelected ? 'color: red': ''">
                                        {{ t('PROPERTIES.BLOCK') }} {{ block.text }}
                                    </a>
                                </div>
                                <div :id="'toggleIconsCollapseM' + block.title" class="collapse"
                                     :aria-labelledby="'toggleIcons' + block.title"
                                     :data-parent="'#toggleIcons' + block.title">
                                    <div class="accordion-body">
                                        <div class="text-center"
                                             v-if="isLoadingLots && (isLoadingLots.block == block.text && isLoadingLots.isLoading)">
                                            <div class="spinner-border m" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <div class="row gutters">
                                            <div v-for="lot in lots[block.text]" :key="lot.id"
                                                 class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-6 text-center position-relative">
                                                <div class="d-flex h-100">
                                                    <i v-if="lot.selected" class="icon-check-circle"
                                                       style="position: absolute; font-size: 20px; z-index: 2"></i>
                                                    <div class="card w-100" :id="lot.id" @click="selectLot(lot)"
                                                         :style="[lot.selected ? styleSelect :'opacity: 0.4; max-height: 85%;', `background-color: ${lot.status.backgroud_collor}`, 'cursor: pointer']">
                                                        <h3 class="mt-1" :style="{'color': lot.status.text_collor}">{{
                                                                lot.name
                                                            }}</h3>
                                                        <p class="hide-text" :style="{'color': lot.status.text_collor}">
                                                            {{ lot.properties_type_name }}</p>
                                                        <p :style="{'color': lot.status.text_collor}">{{
                                                                lot.total_area
                                                            }} m²</p>
                                                        <p class="font-weight-bold"
                                                           :style="{'color': lot.status.text_collor}">
                                                            {{ toCurrency(lot.price) }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="applyType === '3'">
                    <div v-for="(type, key) in typesLot" :key="key">
                        <div class="accordion toggle-icons" :id="`toggleIconsBlock${type.text}`">
                            <div class="accordion-container">
                                <div class="accordion-header" :id="`toggleIconsBlocks${type.text}`">
                                    <div class="row">
                                        <a data-toggle="collapse"
                                           :data-target="`#toggleIconsCollapseAllotment${type.text}`"
                                           :id="`openBlock${type.text}`"
                                           class="col-6"
                                           aria-controls="toggleIconsCollapseAllotment">
                                            {{ type.text }}
                                        </a>
                                        <div class="col-3"></div>
                                        <div class="custom-control custom-switch list-primary text-right col-3">
                                            <input type="checkbox" class="custom-control-input" :value="type.id"
                                                   v-model="typeSelected"
                                                   :id="`customSwitch${key}`">
                                            <label class="custom-control-label mr-2"
                                                   :for="`customSwitch${key}`"
                                                   :style="isInvalidSelected ? 'color: red': ''">Selecionar</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-end" style="margin-top: -1rem">
                <div>
                    <button class="btn btn-secondary" data-dismiss="modal">{{ t('ACTIONS.CLOSE') }}
                    </button>
                </div>
                <div>
                    <button class="btn btn-primary" @click="validateAba">{{ t('LOTS.APPLY') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import Select2 from 'vue3-select2-component';
import Lots from "@/services/Lots";
import {Money3Directive} from 'v-money3';
import Readjustment from "../../services/Readjustment";

export default {
    name: "readjustmentLotComponent",
    directives: {money3: Money3Directive},
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    components: {
        Select2
    },
    props: {
        propertiesStatus: {
            type: Array,
            required: true
        },
        propertiesType: {
            type: Array,
            required: true
        },
        allotmentIndex: {
            type: Object,
            required: true
        },
        allotmentIdIndex: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allotments: null,
            allotment_id: null,
            allotment: null,
            typesLot: null,
            typeSelected: [],
            isInvalidSelected: null,
            lots: [],
            lotsSelected: [],
            isLoadingLots: null,
            isValueInvalid: false,
            isAllotmentInvalid: false,
            isApplyInvalid: false,
            blocks: null,
            valueReadjustment: {
                type: null,
                value: null
            },
            blockSelect: [],
            valueConfig: {},
            styleSelect: 'opacity: 1;',
            applyType: null,
            optionsApply: [
                {
                    id: 0,
                    text: 'Loteamento'
                },
                {
                    id: 1,
                    text: 'Quadra'
                },
                {
                    id: 2,
                    text: 'Lote'
                },
                {
                    id: 3,
                    text: 'Tipo de Lote'
                }
            ]
        }
    },
    mounted() {
        this.allotments = this.allotmentIndex;
        this.allotment_id = this.allotmentIdIndex;
    },
    watch: {
        allotment_id: function () {
            this.clearFields();
            this.indexBlocks(this.allotment_id);
        }
    },
    methods: {
        createConfigMoney(type) {
            if (type == 'FIXED') {
                this.valueReadjustment.type = 0;
                this.valueConfig.precision = 2;
                this.valueConfig.suffix = '';
                this.valueConfig.decimal = ',';
                this.valueConfig.thousands = '.';
                this.valueConfig.prefix = 'R$ ';
                this.valueConfig.disabled = false;
                this.valueConfig.max = 100000000000;
                this.valueConfig.minimumNumberOfCharacters = 1;
            }
            if (type == 'FOOTAGE') {
                this.valueReadjustment.type = 2;
                this.valueConfig.suffix = '';
                this.valueConfig.precision = 2;
                this.valueConfig.decimal = ',';
                this.valueConfig.thousands = '.';
                this.valueConfig.prefix = 'R$ ';
                this.valueConfig.disabled = false;
                this.valueConfig.max = 100000000000;
                this.valueConfig.minimumNumberOfCharacters = 1;
            }
            if (type == 'PERCENTAGE') {
                this.valueReadjustment.type = 1;
                this.valueConfig.max = 100000000000;
                this.valueConfig.prefix = '';
                this.valueConfig.decimal = ',';
                this.valueConfig.thousands = '.';
                this.valueConfig.precision = 6;
                this.valueConfig.suffix = ' %';
                this.valueConfig.disabled = false;
                this.valueConfig.minimumNumberOfCharacters = 0;
            }
        },

        async indexLotsByAllotment(allotment_id, block_id = false) {
            if (block_id !== false && !this.lots[block_id]) {
                this.isLoadingLots = {block: block_id, isLoading: true};

                await Lots.lotsByAllotmentAndBlock(allotment_id, block_id).then(resp => {
                    this.lots[block_id] = []
                    if (resp.data?.lots?.length) {
                        const lots = resp.data.lots;
                        lots.forEach(i => {
                            i.status = this.propertiesStatus.find(s => s.id === i.properties_status_id);
                            i.properties_type_name = this.propertiesType.find(t => t.id === i.properties_type_id).text;
                        });
                        lots.forEach(item => {
                            if (item.status.type === 4 || item.status.type === 0) {
                                this.lots[block_id].push(item)
                            }
                        })
                    }
                    this.isLoadingLots.isLoading = false;
                }).catch(error => {
                    this.errorMsg(error);
                    this.isLoadingLots.isLoading = false;
                });
            }
        },

        toCurrency(value) {
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },

        changeAllotment() {
            this.$store.commit('changeLoading', true);
            if (this.applyType === '1') {
                this.indexBlocks();
            } else if (this.applyType === '2') {
                this.indexBlocks();
            } else if (this.applyType === '3') {
                this.getTypesLotes();
            } else {
                this.indexBlocks();
                this.allotments.forEach(item => {
                    if (item.id === this.allotment_id) this.allotment = item;
                })
            }
        },

        async getTypesLotes() {
            await Lots.getTypesLotes(this.allotment_id).then(resp => {
                this.typesLot = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(() => this.$store.commit('changeLoading', false))
        },

        async indexBlocks() {
            this.$store.commit('changeLoading', true);
            await Lots.blocksListActivesAndBlocked(this.allotment_id).then(resp => {
                this.blocks = resp.data;
            }).catch(error => {
                this.errorMsg(error);
            });

            if (!this.blocks.validations) {
                this.blocks.forEach(block => {
                    block.title = block.text.replaceAll(" ", "");
                });
                this.blocks.sort((a, b) => a.text < b.text ? -1 : a.text > b.text ? 1 : 0);
                this.indexLotsByAllotment(this.allotment_id)
            }
            this.$store.commit('changeLoading', false);
        },

        selectLot(lot) {
            if (this.lotsSelected.includes(lot)) {
                this.lotsSelected = this.lotsSelected.filter(item => item !== lot);
            } else this.lotsSelected.push(lot)
            lot.selected = !lot.selected;
        },

        errorMsg(error) {
            const erro = error.response.data.validations.errors;

            if (erro.sql || !erro) {
                this.toast.error(error.response.data.validations.message, {
                    timeout: false,
                });
            } else {
                for (let column in erro) {
                    for (let msg in erro[column]) {
                        this.toast.error(erro[column][msg], {timeout: false});
                    }
                }
            }
        },
        validateAba() {
            let next = true;
            if (!this.lotsSelected.length && !this.typeSelected.length && !this.blockSelect.length && !this.allotment) {
                next = false;
                this.isInvalidSelected = true;
            } else this.isInvalidSelected = false;
            if (this.valueReadjustment.value == 0.00) {
                this.isValueInvalid = true;
                next = false;
            } else this.isValueInvalid = false;
            if (this.applyType === null) {
                this.isApplyInvalid = true;
                next = false;
            } else this.isApplyInvalid = false;
            if (!this.allotment_id) {
                this.isAllotmentInvalid = true;
                next = false;
            }
            this.isAllotmentInvalid = false;
            if (next) this.switchReadjustment();
        },
        createAllotmentReadjustment(data) {
            this.$swal.fire({
                title: this.t('GENERAL.WARNING'),
                text: this.t('LOTS.WARNING_FIRE_ALLOTMENT_READJUSTMENT'),
                icon: 'warning',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Readjustment.createAllotmentReadjustment(this.allotment_id, data).then(resp => {
                        document.getElementById('closeX').click();
                        this.clearFields();
                        this.toast.success(resp.data.validations.message);
                        this.$store.commit('changeLoading', false);
                        this.$emit('Readjusted');
                    }).catch((err) => {
                        this.errorMsg(err);
                        this.$store.commit('changeLoading', false);
                    });
                }
            });
        },
        createBlockReadjustment(data) {
            this.$swal.fire({
                title: this.t('GENERAL.WARNING'),
                text: this.t('LOTS.WARNING_FIRE_ALLOTMENT_READJUSTMENT'),
                icon: 'warning',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    data.blocks = [];
                    this.$store.commit('changeLoading', true);
                    this.blockSelect.forEach(item => {
                        this.blocks.forEach(block => {
                            if (item === block.id) data.blocks.push({name: block.text})
                        })
                    });
                    Readjustment.createBlockReadjustment(this.allotment_id, data).then(resp => {
                        document.getElementById('closeX').click();
                        this.clearFields();
                        this.toast.success(resp.data.validations.message);
                        this.$store.commit('changeLoading', false);
                        this.$emit('Readjusted');
                    }).catch((err) => {
                        this.errorMsg(err);
                        this.$store.commit('changeLoading', false);
                    });
                }
            });
        },
        createLotReadjustment(data) {
            data.properties = [];
            this.$store.commit('changeLoading', true);
            this.lotsSelected.forEach(item => {
                data.properties.push({id: item.id});
            })
            Readjustment.createLotReadjustment(this.allotment_id, data).then(resp => {
                document.getElementById('closeX').click();
                this.clearFields();
                this.toast.success(resp.data.validations.message);
                this.$store.commit('changeLoading', false);
                this.$emit('Readjusted');
            }).catch((err) => {
                this.errorMsg(err);
                this.$store.commit('changeLoading', false);
            });
        },
        createLotTypeReadjustment(data) {
            this.$swal.fire({
                title: this.t('GENERAL.WARNING'),
                text: this.t('LOTS.WARNING_FIRE_ALLOTMENT_READJUSTMENT'),
                icon: 'warning',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Continuar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    data.propertiesType = [];
                    this.$store.commit('changeLoading', true);
                    this.typeSelected.forEach(item => {
                        data.propertiesType.push({id: item})
                    })
                    Readjustment.createLotTypeReadjustment(this.allotment_id, data).then(resp => {
                        document.getElementById('closeX').click();
                        this.clearFields();
                        this.toast.success(resp.data.validations.message);
                        this.$store.commit('changeLoading', false);
                        this.$emit('Readjusted');
                    }).catch((err) => {
                        this.errorMsg(err);
                        this.$store.commit('changeLoading', false);
                    });
                }
            });
        },
        clearFields() {
            this.lots = [];
            this.blocks = null;
            this.valueReadjustment = {
                type: null,
                value: null
            };
            this.blockSelect = [];
            this.typeSelected = [];
            this.typesLot = null;
            this.valueConfig = {};
            this.applyType = null;
            this.lotsSelected = [];
        },
        valueReplace(valueReadjustment) {
            if (valueReadjustment.type === 1) {
                let value = (((valueReadjustment.value.replace(' %', '').replace('.', '')).replace(',', '.')));
                return value;
            } else {
                return ((valueReadjustment.value.replace('R$ ', '')).replace('.', '')).replace(',', '.');
            }
        },
        switchReadjustment() {
            let value = this.valueReplace(this.valueReadjustment);
            switch (this.applyType) {
                case '0':
                    this.createAllotmentReadjustment({
                        type: this.valueReadjustment.type,
                        value: value
                    });
                    break;
                case '1':
                    this.createBlockReadjustment({
                        type: this.valueReadjustment.type,
                        value: value
                    });
                    break;
                case '2':
                    this.createLotReadjustment({
                        type: this.valueReadjustment.type,
                        value: value
                    });
                    break;
                case '3':
                    this.createLotTypeReadjustment({
                        type: this.valueReadjustment.type,
                        value: value
                    })
                    break;
            }
        }
    }
}
</script>

<style scoped>

</style>